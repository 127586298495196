<template>
  <van-cell title="使用报告" is-link :to="{ name: 'report' }" />
  <div class="box">
    <van-cell-group>
      <van-cell title="点赞评论" is-link :to="{ name: 'commentsLike' }" />
      <van-cell title="点赞新闻" is-link :to="{ name: 'newsLike' }" />
      <van-cell title="收藏内容" is-link :to="{ name: 'collect' }" />
    </van-cell-group>
  </div>
  <van-cell
    :title="$userInfo ? '退出账号' : '去登陆'"
    is-link
    style="color: #e74c3c"
    @click="toLoginOrOut($userInfo)"
  />
</template>

<script>
import { Dialog, Toast } from "vant";
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const toLoginOrOut = (is_login) => {
      if (!is_login) {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        return setTimeout(() => {
          router.push({ name: "login" });
        }, 700);
      }
      Dialog.confirm({
        title: "退出账号",
        message: "退出账号后，部分功能将无法使用",
      })
        .then(() => {
          localStorage.clear();
          Toast("您已退出");
          setTimeout(() => {
            router.push({ name: "home" });
          }, 700);
        })
        .catch(() => {
          // on cancel
        });
    };
    return { toLoginOrOut };
  },
};
</script>

<style>
.box {
  padding: 10px 0;
  background: var(--van-gray-2);
}
</style>