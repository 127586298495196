<template>
  <NavBar />
  <User />
  <Option />
</template>

<script>
import NavBar from "./components/NavBar.vue";
import User from "./components/User.vue";
import Option from "./components/Option.vue";
export default {
  components: {
    NavBar,
    User,
    Option,
  },
};
</script>

<style lang="less" scoped>
</style>