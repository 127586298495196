<template>
  <NavBar title="用户设置" />
  <UserSetting />
</template>

<script>
import { ref } from "vue";
import NavBar from "../../components/navbar/index.vue";
import UserSetting from "./components/UserSetting.vue";

export default {
  components: {
    NavBar,
    UserSetting,
  },
  setup() {
    const showUserInfo = ref();
    return { showUserInfo };
  },
};
</script>

