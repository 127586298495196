<template>
  <van-dialog
    v-model:show="show"
    title="修改个人信息"
    show-cancel-button
    close-on-click-overlay
    :before-close="beforeUpdate"
  >
    <div class="img">
      <van-uploader
        :after-read="updateAvatar"
        :max-size="1024 * 1024"
        @oversize="onOversize"
      >
        <van-image
          round
          width="100px"
          height="100px"
          fit="cover"
          :src="
            userinfo.avatar
              ? userinfo.avatar
              : 'https://img.yzcdn.cn/vant/cat.jpeg'
          "
          @click="updateAvatar"
        />
      </van-uploader>
    </div>
    <van-field
      v-model="nick_name"
      label="昵称"
      placeholder="要修改的昵称"
      clearable
    />
  </van-dialog>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { Toast } from "vant";
import router from "../../../router";

// 上传图片
const useUploadAvatarEffect = (userinfo) => {
  const store = useStore();
  const onOversize = () => {
    Toast("文件大小不能超过 1 MB");
  };
  const updateAvatar = (file) => {
    const formData = new FormData();
    formData.append("file", file.file);
    store.dispatch("user/uploadAvatar", formData).then((res) => {
      if (res) {
        userinfo.value.avatar = res;
      } else {
        Toast.fail("错误了");
      }
    });
  };
  return { onOversize, updateAvatar };
};
// 修改用户信息
const useUpdateUserInfoEffect = (show) => {
  const store = useStore();
  const userinfo = ref(JSON.parse(localStorage.getItem("userinfo")));
  const nick_name = ref("");
  const updateUserInfo = () => {
    store
      .dispatch("user/updateUserInfo", {
        nick_name: nick_name.value,
        avatar: userinfo.value.avatar,
      })
      .then((res) => {
        if (res) {
          Toast.success("修改成功");
          router.go(0)
          show.value = false;
        } else {
          Toast.fail("修改失败");
        }
      })
      .catch((err) => {
        console.log(err);
        Toast.fail(err);
      });
  };
  const beforeUpdate = (action) => {
    if (!action || action === "cancel") {
      show.value = false;
    } else if (nick_name.value) {
      updateUserInfo();
    } else {
      Toast("不能为空");
    }
  };
  return { userinfo, nick_name, beforeUpdate, updateUserInfo };
};

export default {
  setup() {
    const show = ref(false);
    const showModal = () => {
      show.value = true;
    };
    const { userinfo, nick_name, beforeUpdate, updateUserInfo } =
      useUpdateUserInfoEffect(show);
    const { onOversize, updateAvatar } = useUploadAvatarEffect(userinfo);
    return {
      show,
      showModal,
      userinfo,
      nick_name,
      beforeUpdate,
      updateUserInfo,
      onOversize,
      updateAvatar,
    };
  },
};
</script>

<style lang="less" scoped>
.img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
</style>