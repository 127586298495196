<template>
  <van-nav-bar title="个人中心">
    <template #left>
      <van-icon
        name="arrow-left"
        size="18"
        :color="$userSetting ? $userSetting.theme_color : 'red'"
        @click="toBack"
      />
    </template>
    <template #right>
      <van-icon
        name="setting-o"
        size="18"
        @click="toSetting"
        :color="$userSetting ? $userSetting.theme_color : 'red'"
      />
    </template>
  </van-nav-bar>
</template>

<script>
import { useRouter } from "vue-router";
import { Toast } from "vant";
export default {
  setup() {
    const router = useRouter();
    const toBack = () => {
      history.back();
    };
    const toSetting = () => {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      setTimeout(() => {
        router.push({ name: "setting" });
        Toast.clear();
      }, 400);
    };
    return { toBack, toSetting };
  },
};
</script>

<style>
</style>