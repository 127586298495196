<template>
  <NavBar title="使用报告" />
  <div
    style="color: #c8c8c8; text-align: center; padding: 20px; font-size: 80%"
  >
    数据更新日期：{{ userUsingData.data_update }}
  </div>
  <div>
    <div class="useData">
      <div class="read_situ nochart page">
        <div class="text content">
          <van-image
            width="300px"
            height="100px"
            fit="contain"
            src="https://cdn.dc-news.kakkk.net/img/1acb64a74b2b82c7.png"
          />
          <div class="intro">到目前为止</div>
          <div class="intro">
            你一共看了<span class="emp">{{ news_count }}</span
            >篇新闻报道
          </div>
          <!-- 补新闻篇章的数据 -->
          <div class="intro">
            累计阅读<span class="emp">{{ userUsingData.word_count }}</span
            >个字
          </div>
          <div class="intro">
            为<span class="emp">{{ userUsingData.news_like_count }}</span
            >篇文章点了赞
          </div>
        </div>
      </div>

      <div class="channel chartarea page">
        <div class="content">
          <van-image
            width="300px"
            height="100px"
            fit="contain"
            src="https://cdn.dc-news.kakkk.net/img/899e3911caa5c13d.png"
          />
          <div
            class="intro title"
            style="text-align: center; padding-top: 40px"
          >
            最常浏览的频道排行:
          </div>
          <div
            class="chartarea_chart"
            id="radarContainer1"
            style="padding-left: 10px"
          ></div>
          <div class="intro">
            <span class="emp">{{ most_channel }}</span
            >是你最喜欢的频道
          </div>
        </div>
      </div>

      <div class="comment nochart page">
        <div class="text content">
          <van-image
            width="300px"
            height="100px"
            fit="contain"
            src="https://cdn.dc-news.kakkk.net/img/58dab5dba3af8aba.png"
          />
          <div class="intro">在看时事新闻的时候</div>
          <div class="intro">
            你一共留下了<span class="emp">{{
              userUsingData.comment_count
            }}</span
            >条评论
          </div>
          <!-- 补新闻篇章的数据 -->
          <div class="intro">
            总共给<span class="emp">{{ userUsingData.comment_like_count }}</span
            >评论点了赞
          </div>
        </div>
      </div>
      <div class="keyWord nochart page">
        <div class="content">
          <van-image
            width="300px"
            height="100px"
            fit="contain"
            src="https://cdn.dc-news.kakkk.net/img/207309b33953b50c.png"
          />
          <div class="intro" style="margin-top: 60px; font-size: 24px">
            我的词云
          </div>
          <van-image
            width="85%"
            height="85%"
            fit="contain"
            :src="userUsingData.word_cloud"
            style="padding: 0 20px"
            @click="preImage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, toRaw, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import miment from "miment";
import NavBar from "../../components/navbar/index.vue";
import { ImagePreview } from "vant";
const echarts = require("echarts");
require("echarts/lib/chart/bar");

export default {
  components: {
    NavBar,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {
    const news_count = ref(0);
    const userUsingData = ref({});
    const preImage = () => {
      ImagePreview([userUsingData.value.word_cloud]);
    };
    const userSetting =
      getCurrentInstance().appContext.config.globalProperties.$userSetting;
    const most_channel = ref("");
    onMounted(async () => {
      const store = useStore();
      await store.dispatch("user/getUserUsingData").then((res) => {
        if (res) {
          userUsingData.value = store.state.user.userUsingData;
          userUsingData.value.data_update = ref(
            miment()
              .distance(userUsingData.value.data_update)
              .format("YYYY年MM月DD日")
          );
        }
      });
      var myChart1 = echarts.init(
        document.getElementById("radarContainer1"),
        null,
        {
          width: 315,
          height: 280,
        }
      );
      let channelCount = toRaw(userUsingData.value).channel_count;
      channelCount = JSON.parse(
        JSON.stringify(channelCount)
          .replace(/news_hot/g, "热点")
          .replace(/news_society/g, "社会")
          .replace(/news_entertainment/g, "娱乐")
          .replace(/news_tech/g, "技术")
          .replace(/news_military/g, "军事")
          .replace(/news_sports/g, "运动")
          .replace(/news_car/g, "汽车")
          .replace(/news_food/g, "美食")
      );
      Object.keys(channelCount).forEach((key) => {
        news_count.value += channelCount[key];
      });
      const sort_values = Object.values(channelCount).sort((a, b) => a - b);
      const sort_dict = {};
      for (let i of sort_values) {
        for (let channel in channelCount) {
          if (channelCount[channel] === i) {
            sort_dict[channel] = i;
            delete channelCount[channel];
          }
        }
      }
      let channelList = Object.keys(sort_dict);
      most_channel.value = Object.keys(sort_dict)[7];
      // 频道浏览排行
      myChart1.setOption({
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: channelList,
        },
        series: [
          {
            name: "阅读篇数",
            type: "bar",
            yAxisIndex: 0,
            data: sort_values,
            itemStyle: {
              color: userSetting.theme_color,
              barBorderRadius: 3,
            },
          },
        ],
      });
    });
    return {
      userUsingData,
      preImage,
      news_count,
      most_channel,
    };
  },
};
</script>

<style lang="less" scoped>
body {
  padding: 0;
  margin: 0;
}
.page {
  width: 100%;
  height: 280px;
  position: relative;
  .content {
    width: 300px;
    height: 280px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
.intro {
  font-size: 15px;
  letter-spacing: 2px;
  line-height: 20px;
  text-align: center;
}
.emp {
  font-size: 20px;
  display: inline-block;
  line-height: 20px;
}
.nochart {
  .text {
    width: 300px;
    text-align: center;
    .intro {
      height: 42px;
      line-height: 42px;
    }
    .emp {
      line-height: 42px;
    }
  }
}
.chartarea {
  width: 310px;
  margin-left: auto;
  margin-right: auto;
  &_chart {
    margin-top: -20px;
  }
}
.title {
  margin-bottom: -15px;
  text-align: left;
}
.comment {
  margin-top: 150px;
}
</style>