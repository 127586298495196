<template>
  <nav-bar title="点赞评论"></nav-bar>
  <Comment :commentsList="commentsList" v-if="commentsList[0]"/>
  <van-empty description="没有点赞的评论~" v-else/>
</template>
<script>
import { computed, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import NavBar from "../../components/navbar/index.vue";
import Comment from "../../components/comments/CommentItem.vue";

export default {
  components: {
    NavBar,
    Comment,
  },
  setup() {
    const store = useStore();
    const commentsList = computed(() => store.state.user.likeCommentList);
    onMounted(() => {
      store.dispatch("user/getUserLikeCommentList");
    });
    return { commentsList };
  },
};
</script>
