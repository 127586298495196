<template>
  <div class="warp">
    <div class="warp__user">
      <img
        :src="
          $userInfo
            ? $userInfo.avatar
            : 'https://cdn.dc-news.kakkk.net/img/avatar/avatar.jpg'
        "
      />
      <div class="warp__user__name">
        {{ $userInfo ? $userInfo.nick_name : "您还没登陆" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.warp {
  height: calc(100vh - 290px);
  display: flex;
  justify-content: center;
  align-items: center;
  &__user {
    text-align: center;
    img {
      // box-shadow: 1px 1px 5px #f4f4f4;
      border-radius: 50%;
      height: 110px;
      width: 110px;
    }
    &__name {
      padding: 10px 0;
      letter-spacing: 2px;
      font-size: 18px;
    }
  }
}
</style>