<template>
  <van-cell-group @click="showUserInfo">
    <van-cell
      title="昵称"
      :value="$userInfo.nick_name ? $userInfo.nick_name : '未设置'"
    />
    <van-cell title="邮箱" :value="$userInfo.email" />
  </van-cell-group>
  <van-cell-group>
    <van-cell title="字体" is-link @click="fontShow = true" />
    <van-cell title="主题色" is-link @click="themeShow = true" />
    <van-cell title="暗黑模式" is-link>
      <template #right-icon>
        <van-switch
          v-model="is_dark"
          size="24"
          @change="changeMode(1)"
          :active-color="$userSetting ? $userSetting.theme_color : 'red'"
        />
      </template>
    </van-cell>
    <van-cell title="省流模式" is-link>
      <template #right-icon>
        <van-switch
          v-model="is_save"
          size="24"
          @change="changeMode(0)"
          :active-color="$userSetting ? $userSetting.theme_color : 'red'"
        />
      </template>
    </van-cell>
  </van-cell-group>
  <div class="bottom">
    <van-button
      type="primary"
      class="bottom__btn"
      @click="showUserInfo"
      :color="$userSetting ? $userSetting.theme_color : 'red'"
      >修改个人信息</van-button
    >
  </div>
  <ShowUserInfo ref="showModalRef" />
  <!-- 字体 -->
  <van-action-sheet
    v-model:show="fontShow"
    cancel-text="确定"
    close-on-click-action
    title="选择字体大小"
    @cancel="comfirmFontSize"
    @open="openChangeFontSize"
    @click-overlay="cancelChangeFontSize"
  >
    <div class="example" :style="{ fontSize: fontSize + 'px' }">
      这是一段示例文字
    </div>
    <div class="font-wrap">
      <span class="font-wrap__text">小</span>
      <van-slider
        v-model="fontSize"
        min="12"
        max="24"
        bar-height="5px"
        class="font-wrap__slider"
      />
      <span class="font-wrap__text">大</span>
    </div>
  </van-action-sheet>
  <!-- 主题色 -->
  <van-action-sheet
    v-model:show="themeShow"
    title="选择主题色"
    cancel-text="取消"
    close-on-click-action
  >
    <div class="theme-wrap" v-show="$userSetting.dark_mode == 2">
      <div
        class="theme-wrap__color theme-wrap__color--red"
        @click="chooseTheme('#ff3f34')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--purple"
        @click="chooseTheme('#5f27cd')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--pink"
        @click="chooseTheme('#f368e0')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--green"
        @click="chooseTheme('#10ac84')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--blue"
        @click="chooseTheme('#0abde3')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--orange"
        @click="chooseTheme('#ffa801')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--grey"
        @click="chooseTheme('#8395a7')"
      ></div>
    </div>
    <div class="theme-wrap" v-show="$userSetting.dark_mode == 1">
      <div
        class="theme-wrap__color theme-wrap__color--red-dark"
        @click="chooseTheme('#882924')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--purple-dark"
        @click="chooseTheme('#5027a1')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--pink-dark"
        @click="chooseTheme('#974c8d')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--green-dark"
        @click="chooseTheme('#0d7259')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--blue-dark"
        @click="chooseTheme('#0d7a92')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--orange-dark"
        @click="chooseTheme('#6e4905')"
      ></div>
      <div
        class="theme-wrap__color theme-wrap__color--grey-dark"
        @click="chooseTheme('#5c5c5c')"
      ></div>
    </div>
  </van-action-sheet>
</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ShowUserInfo from "./ShowUserInfo.vue";
import { Toast } from "vant";

// 修改用户信息
const useUpdataUserInfoEffect = () => {
  const userinfo = ref(JSON.parse(localStorage.getItem("userinfo")));
  const showModalRef = ref();
  const showUserInfo = () => {
    showModalRef.value.showModal();
  };
  return { userinfo, showModalRef, showUserInfo };
};

// 修改字体设置
const useUpdateFontEffect = () => {
  const store = useStore();
  const fontShow = ref(false);
  const fontSize = ref(store.state.app.userSetting.front_size);
  const temFontSize = ref(store.state.app.userSetting.front_size);
  watch(
    () => store.state.app.userSetting.front_size,
    (newVal) => {
      fontSize.value = newVal;
      temFontSize.value = newVal;
    }
  );
  const { updateUserSetting } = useHttpEffect();
  const openChangeFontSize = () => {
    temFontSize.value = fontSize.value;
  };
  // 点击遮盖层
  const cancelChangeFontSize = () => {
    fontSize.value = temFontSize.value;
  };
  const comfirmFontSize = () => {
    updateUserSetting({
      theme_color: store.state.app.userSetting.theme_color,
      front_size: fontSize.value,
      dark_mode: store.state.app.userSetting.dark_mode, //暗黑模式，1、开启，2、关闭
      use_mode: store.state.app.userSetting.use_mode, //使用模式，1、普通，2、省流
    });
  };
  return {
    fontShow,
    fontSize,
    temFontSize,
    openChangeFontSize,
    cancelChangeFontSize,
    comfirmFontSize,
  };
};

// 修改主题色设置
const useUpdateThemeEffect = () => {
  const store = useStore();
  const themeShow = ref(false);
  const { updateUserSetting } = useHttpEffect();
  const chooseTheme = (theme) => {
    updateUserSetting({
      theme_color: theme,
      front_size: store.state.app.userSetting.front_size,
      dark_mode: store.state.app.userSetting.dark_mode, //暗黑模式，1、开启，2、关闭
      use_mode: store.state.app.userSetting.use_mode, //使用模式，1、普通，2、省流
    });
    themeShow.value = false;
  };
  return { themeShow, chooseTheme };
};

// 设置暗黑、省流模式
const useSettingModeEffet = () => {
  const store = useStore();
  const is_dark = ref(
    store.state.app.userSetting.dark_mode == 1 ? true : false
  );
  const is_save = ref(store.state.app.userSetting.use_mode == 1 ? false : true);
  watch(
    () => store.state.app.userSetting.dark_mode,
    (newVal) => {
      is_dark.value = newVal == 1 ? true : false;
    }
  );
  watch(
    () => store.state.app.userSetting.use_mode,
    (newVal) => {
      is_save.value = newVal == 1 ? false : true;
    }
  );
  const { updateUserSetting } = useHttpEffect();
  const changeMode = (dark) => {
    let themeColor = store.state.app.userSetting.theme_color
      ? store.state.app.userSetting.theme_color
      : "#5f27cd";
    if (dark) {
      switch (themeColor) {
        // 亮 -> 暗
        case "#ff3f34": // 红色
          themeColor = "#882924";
          break;
        case "#5f27cd": // 紫色
          themeColor = "#5027a1";
          break;
        case "#f368e0": // 粉色
          themeColor = "#974c8d";
          break;
        case "#10ac84": // 绿色
          themeColor = "#0d7259";
          break;
        case "#0abde3": // 蓝色
          themeColor = "#0d7a92";
          break;
        case "#ffa801": //橙色
          themeColor = "#6e4905";
          break;
        case "#8395a7": //灰色
          themeColor = "#5c5c5c";
          break;
        // 暗 -> 亮
        case "#882924": // 红色
          themeColor = "#ff3f34";
          break;
        case "#5027a1": // 紫色
          themeColor = "#5f27cd";
          break;
        case "#974c8d": // 粉色
          themeColor = "#f368e0";
          break;
        case "#0d7259": // 绿色
          themeColor = "#10ac84";
          break;
        case "#0d7a92": // 蓝色
          themeColor = "#0abde3";
          break;
        case "#6e4905": //橙色
          themeColor = "#ffa801";
          break;
        case "#5c5c5c": //灰色
          themeColor = "#8395a7";
          break;
        default:
          break;
      }
    }
    updateUserSetting({
      theme_color: themeColor,
      front_size: store.state.app.userSetting.front_size,
      dark_mode: is_dark.value ? 1 : 2, //暗黑模式，1、开启，2、关闭
      use_mode: is_save.value ? 2 : 1, //使用模式，1、普通，2、省流
    });
  };
  return { is_dark, is_save, changeMode };
};

// 网路请求相关
const useHttpEffect = () => {
  const store = useStore();
  const router = useRouter();
  const updateUserSetting = (data) => {
    store.dispatch("app/updateUserSetting", data).then((res) => {
      if (res) {
        Toast.success("修改成功");
        setTimeout(() => {
          router.go(0);
        }, 500);
      }
    });
  };
  return { updateUserSetting };
};

export default {
  components: {
    ShowUserInfo,
  },
  setup() {
    const { showModalRef, userinfo, showUserInfo } = useUpdataUserInfoEffect();
    const {
      fontShow,
      fontSize,
      temFontSize,
      openChangeFontSize,
      cancelChangeFontSize,
      comfirmFontSize,
    } = useUpdateFontEffect();
    const { themeShow, chooseTheme } = useUpdateThemeEffect(fontSize);
    const { is_dark, is_save, changeMode } = useSettingModeEffet();
    return {
      showModalRef,
      userinfo,
      showUserInfo,
      fontShow,
      fontSize,
      temFontSize,
      themeShow,
      openChangeFontSize,
      cancelChangeFontSize,
      comfirmFontSize,
      chooseTheme,
      is_dark,
      is_save,
      changeMode,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../../style/theme.less";
.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  &__btn {
    border-radius: 10px;
    height: 35px;
    padding: 0 40px;
    letter-spacing: 1px;
    // font-size: 15px;
  }
}
.theme-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  &__color {
    margin: 5px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    // 亮色系
    &--red {
      background: @red;
    }
    &--blue {
      background: @blue;
    }
    &--green {
      background: @green;
    }
    &--pink {
      background: @pink;
    }
    &--orange {
      background: @orange;
    }
    &--purple {
      background: @purple;
    }
    &--grey {
      background: @grey;
    }
    // 暗色系
    &--red-dark {
      background: @dark-red;
    }
    &--blue-dark {
      background: @dark-blue;
    }
    &--green-dark {
      background: @dark-green;
    }
    &--pink-dark {
      background: @dark-pink;
    }
    &--orange-dark {
      background: @dark-orange;
    }
    &--purple-dark {
      background: @dark-purple;
    }
    &--grey-dark {
      background: @dark-grey;
    }
  }
}
.example {
  padding: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.font-wrap {
  display: flex;
  align-items: center;
  padding: 0 10px;
  &__text {
    color: #999;
  }
  &__slider {
    margin: 20px auto;
    width: 70%;
  }
}
</style>